import { render, staticRenderFns } from "./Alipay.vue?vue&type=template&id=40011550&scoped=true"
import script from "./Alipay.vue?vue&type=script&lang=js"
export * from "./Alipay.vue?vue&type=script&lang=js"
import style0 from "./Alipay.vue?vue&type=style&index=0&id=40011550&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40011550",
  null
  
)

export default component.exports